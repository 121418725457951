import React from 'react';
import { GoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { useLogin } from './LoginProvider';
import { useLocation, useNavigate } from 'react-router-dom';

// Google login button logic
const GoogleLoginButtonLogic = () => {
    const { setIsLoggedIn } = useLogin();
    const navigate = useNavigate();
    const location = useLocation();

    // Extract the redirect query parameter from the URL
    const params = new URLSearchParams(location.search);
    const redirect = params.get('redirect');

    const handleLoginSuccess = async (credentialResponse) => {
        try {
            // Send token to backend to handle login or signup
            const responseLogin = await axios.post(process.env.REACT_APP_BASE_SERVER_URL + '/api/v1/users/signupOrLogin', {
                idToken: credentialResponse.credential
            }, {
                withCredentials: true,
                timeout: 30000
            });

            console.log('Setting account logged in');
            setIsLoggedIn(responseLogin.data);

            // Redirect if needed
            if (redirect === 'true') {
                navigate('/');
            }

        } catch (error) {
            setIsLoggedIn(false);
            console.error('Error during login');
        }
    };

    const handleLoginError = () => {
        console.log('Login failed');
        setIsLoggedIn(false);
    };

    return (
        <GoogleLogin
            onSuccess={handleLoginSuccess}
            onError={handleLoginError}
            theme="filled_blue"
            shape="square"
            size="Large"
            text="continue_with"
            width="240"
        />
    );
};

export default GoogleLoginButtonLogic;
