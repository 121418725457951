import React, { useState, useEffect } from 'react';
import Section from './Section';
import './Home.css';
import { Link } from 'react-router-dom';
import { useLogin } from "../UserAutentication/LoginProvider";
import DiscountBanner from "./DiscountBanner/DiscountBanner";
import PaywallPage from "../PaywallPage/PaywallPage";
import CreationsPopup from "./CreationsPopup/CreationsPopup";
import CookieConsentBanner from "../CookieConsentBanner/CookieConsentBanner";
import OneTimePaywallPage from "../PaywallPage/OneTimePaywallPage";
import ShortFAQ from "./ShortFAQcomponent/ShortFAQ";

const Home = () => {
    const { hasActiveSubscription } = useLogin();
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const { isLoggedIn } = useLogin();

    // Primary image URLs for the homepage gallery to showcase AI-generated tattoos.
    const MainHomePageImageUrls = [
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/homegallery9.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/homegallery8.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/homegallery3.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery4.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery5.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery6.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery7.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery9.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery11.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery12.png',
        'https://ai-tattoo-website-images.s3.us-east-1.amazonaws.com/home_first_gallery13.png',
    ];

    const blondHairLadyImageUrls = [
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_14942231726650132791.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_9185345822888484342.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_17640812993595825150.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_5640019573988822343.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_1198610166022035163.jpg',
    ];

    const pinkHairLadyImageUrls = [
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_17956667163193335444.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_292669848671852551.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_12214556829072824363.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_2988531963538105001.jpg',
    ];

    const howItWorkImageUrls = [
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.38.27.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.39.55.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.40.46.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.43.40.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.40.46.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.45.03.png',
        'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.46.36.png',
    ];

    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Rotate images on a timer for the main gallery.
    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % MainHomePageImageUrls.length);
        }, 1700);

        return () => clearInterval(intervalId);
    }, []);

    // Open popup on pressing 'A' for showcasing user tattoo creations.
    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === 'a' || event.key === 'A') {
                setIsPopupOpen(true);
            }
        };

        window.addEventListener('keydown', handleKeyPress);

        return () => {
            window.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const handleClosePopup = () => {
        setIsPopupOpen(false);
    };

    const selectedMainHomePageImageUrl = MainHomePageImageUrls[currentImageIndex];
    const selectedBlondImageUrl = blondHairLadyImageUrls[Math.floor(Math.random() * blondHairLadyImageUrls.length)];
    const selectedPinkImageUrl = pinkHairLadyImageUrls[Math.floor(Math.random() * pinkHairLadyImageUrls.length)];
    const selectedHowItWorkImageUrls = howItWorkImageUrls[Math.floor(Math.random() * howItWorkImageUrls.length)];

    return (
        <>
            {/*show cookie banner if he didnt approve*/}
            <CookieConsentBanner/>

            {/*show discount banner for user without an active subscription*/}
            {/*{!hasActiveSubscription && <DiscountBanner/>}*/}

            <div className="home-container">

                <CreationsPopup isOpen={isPopupOpen} onClose={handleClosePopup}/>

                {/*first section - home*/}
                <div className="first-section" style={{ backgroundColor: "#05020e" }}>
                    <div className="section-children">
                        <div className="section-content">
                            <h1 className="main-section-title">AI Tattoo Generator 🔥</h1>
                            <h5>Generate Custom Tattoo Designs from Text</h5>
                            <ul className="main-section-benefits">
                                <li>🌟 Unique AI-Generated Tattoo Designs.</li>
                                <li>💡 Discover Tattoo Styles and Placements.</li>
                                <li>⏳ Save Time—No More Endless Searching!</li>
                                <li>🤑 Affordable Tattoos—No Artist Required!</li>
                                <Link to={'/Generate'} style={{ textDecoration: 'none' }}>
                                    {!isLoggedIn ? (
                                        <>
                                            <button className="get-started-button">
                                                Try it FREE! 🚀
                                            </button>
                                            <span className="new-user-note" style={{
                                                fontSize: '0.9rem',
                                                color: '#f8f5f5',
                                                marginTop: '2px',
                                                marginBottom: '-17px',
                                                display: 'block',
                                            }}>
                                                *Free Try for new users
                                            </span>
                                        </>
                                    ) : (
                                        <button className="get-started-button">
                                            Generate Custom Tattoo
                                        </button>
                                    )}
                                </Link>
                            </ul>
                        </div>
                    </div>
                    <div className="section-image-container">
                        <img src={selectedMainHomePageImageUrl} alt="Tattoo design" className="section-image" />

                        {/* Keyboard shortcut for popup */}
                        <div className="press-a-container">
                            <span className="press-a-text">
                                Press <span className="square-a">A</span> to view TattooGPT.Art Creations
                            </span>
                        </div>
                    </div>
                </div>

                {/* TattooGPT Demo Section with video */}
                <div className="tattoogpt-demo-section" style={{ backgroundColor: "#05020e", padding: "20px 20px" }}>
                    <div className="video-container" style={{ display: "flex", justifyContent: "center", marginTop: "60px" }}>
                        <iframe
                            width="290"
                            height="500"
                            src="https://www.youtube.com/embed/sdi-tpKgm0A?rel=0&modestbranding=1"
                            title="AI Tattoo Generator"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        />
                    </div>
                </div>


                <Section backgroundColor="#05020e" imageUrl={selectedBlondImageUrl}>
                    <div className="why-choose-us-section-content">
                        <h2>Why Choose Us</h2>
                        <p><i className="icon bi bi-check-circle"></i>Top AI Tattoo creator!</p>
                        <p><i className="icon bi bi-check-circle"></i>Design Realistic Tattoos in Seconds!</p>
                        <p><i className="icon bi bi-check-circle"></i>No More Scrolling on Pinterest!</p>
                        <p><i className="icon bi bi-check-circle"></i>Tattoo Ideas to Reality fast.!</p>
                        <p><i className="icon bi bi-check-circle"></i>Save Time & Money - no artist!</p>
                        <p><i className="icon bi bi-check-circle"></i>Enjoy Advanced Features and updates!</p>
                    </div>
                </Section>

            </div>
            {/* Section 3: Black background */}
            {!hasActiveSubscription &&
                <div className="first-section paywall-section" style={{backgroundColor: "black"}}>
                    <div className="section-children">
                        <OneTimePaywallPage/>
                        {/*for subscriptions payment paywall use <PaywallPage/>*/}
                    </div>
                </div>}

                <ShortFAQ />


            <Section backgroundColor="#05020e" imageUrl={selectedPinkImageUrl}>
                <div className="our-customers-reviews-section-content">
                    <h2 className="customers-reviews-title">Customers Reviews</h2>

                    <div className="review-card">
                        <h5><i className="comment-icon bi bi-chat-right-heart-fill"/> "The Future of Tattoo Design!"</h5>
                        <p>"I was searching for a tattoo artist when I discovered this incredible tool. I got 15 stunning designs for my idea—absolutely love it! Thank you so much!"</p>
                        <div className="stars">
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <div className="reviewer-name">- Yuna S.</div>
                        </div>
                    </div>

                    <div className="review-card">
                        <h5><i className="comment-icon bi bi-chat-right-heart-fill"/> "Amazing Results!"</h5>
                        <p>"I've tried several AI tools for tattoo creation, and this is by far the best one. Highly recommended!"</p>
                        <div className="stars">
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <div className="reviewer-name">- Dudu B.</div>
                        </div>
                    </div>

                    <div className="review-card">
                        <h5><i className="comment-icon bi bi-chat-right-heart-fill"/> "Scale Your Tattoo Business!"</h5>
                        <p>"As a tattoo artist, this tool has saved me so much time. My clients absolutely love the results!"</p>
                        <div className="stars">
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-fill"></i>
                            <i className="star-icon bi bi-star-half"></i>
                            <div className="reviewer-name">- Amit M.</div>
                        </div>
                    </div>
                </div>
            </Section>


        </>
    );
}

export default Home;
