import React from 'react';
import './CreationsPopup.css';

const CreationsPopup = ({isOpen, onClose}) => {
    const creationsPopupImages = [
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_2988531963538105001.jpg',
        'https://ai-tattoo-website-images.s3.amazonaws.com/41.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_15819168361566249854.jpg',
        'https://ai-tattoo-website-images.s3.amazonaws.com/43.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_14942231726650132791.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_9185345822888484342.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_17640812993595825150.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_5640019573988822343.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_1198610166022035163.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_10806403131339182085.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_9296435177527934543.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_3320480774488629124.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_14975649372240355930.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_17956667163193335444.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/576369-TKL/AI-Tattoo-Generated-image_292669848671852551.jpg',
        'https://ai-tattoo-s3-bucket.s3.amazonaws.com/888335-WIN/AI-Tattoo-Generated-image_12214556829072824363.jpg',
 ];

    if (!isOpen) return null;

    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <button className="close-button" onClick={onClose}>X</button>
                <div className="image-grid">
                    {creationsPopupImages.map((image, index) => (
                        <img key={index} src={image} alt={`Tattoo ${index + 1}`} className="popup-image"/>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CreationsPopup;
