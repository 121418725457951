import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card} from 'react-bootstrap';
import './RecoveryPage.css';
import CheckoutPage from '../CheckoutPage/CheckoutPage';
import {useLogin} from '../UserAutentication/LoginProvider';
import {useNavigate} from "react-router-dom";
import LoginPage from "../UserAutentication/LogInPage";

const howItWorkImageUrls = [
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.38.27.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.39.55.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.40.46.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.43.40.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.40.46.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.45.03.png',
    'https://ai-tattoo-website-images.s3.amazonaws.com/Screenshot+2024-10-19+at+11.46.36.png',
];

const RecoveryPage = () => {
    const navigate = useNavigate();
    const {hasActiveSubscription, isLoggedIn} = useLogin();
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentImageIndex(prevIndex => (prevIndex + 1) % howItWorkImageUrls.length);
        }, 2000); // Change every 2 seconds

        return () => clearInterval(intervalId);
    }, []);

    // Product details for one-time payment
    const product = {productId: 13, planType: 'one-time', finalPrice: 5};

    // Redirect to home if the user has an active subscription
    if (hasActiveSubscription) {
        navigate('/');
        return null; // Prevent rendering of the page
    }

    return (
        <>
            {!isLoggedIn && <LoginPage/>}
            <Container fluid className="recovery-page">
                <Row className="justify-content-center">
                    <Col xs={12} md={8} lg={6}>
                        <Card className="text-center shadow-lg p-2 mb-5 bg-white rounded custom-card">
                            <Card.Body>
                                <Card.Title className="display-4 mb-2">🚨 Last Chance for AI Tattoos!</Card.Title>
                                <img
                                    src={howItWorkImageUrls[currentImageIndex]}
                                    alt="TattooGPT Design"
                                    className="recovery-image"
                                />
                                <h4 className="offer-text">🌟 Exclusive Offer: <br/> 50 AI Tattoos Designs for
                                    Just <span className="highlight">$5! 🎉</span></h4>
                                <h5 className="one-time-payment">✨ One-time payment only!</h5>
                                <p>Unlock unique AI-generated tattoo designs now! 🖌️✨</p>


                            </Card.Body>
                            <p>Choose payment method:</p>
                            {/* Adding a div to center the CheckoutPage */}
                            <div className="d-flex justify-content-center">
                                <CheckoutPage product={product}/>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default RecoveryPage;
