import React from 'react';
import './Home.css';

const Section = ({backgroundColor, imageUrl, children, textUnderImage}) => {
    return (
        <div className="section" style={{backgroundColor}}>
            <div className="section-children">
                {children}
            </div>
            <div className="section-image-container">
                <img src={imageUrl} alt="Tattoo GPT Art design" className="section-image"/>
            </div>
        </div>
    );
}

export default Section;
