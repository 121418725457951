import React, {useEffect, useState} from 'react';
import {Container, Row, Col, Card, Button} from 'react-bootstrap';
import './LoginPage.css';
import GoogleLoginButtonLogic from "./GoogleLoginButtonLogic";

// Login Component
const LoginPage = () => {
    const [isInAppBrowser, setIsInAppBrowser] = useState(false);
    const [appName, setAppName] = useState('');

    // Function to detect if user is in an in-app browser
    const checkIfInAppBrowser = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect Instagram, YouTube, and Twitter on in-app browsers
        if (/instagram/i.test(userAgent)) {
            setIsInAppBrowser(true);
            setAppName('Instagram');
        }
            // Detect TikTok and set as a regular browser
        // } else if (/BytedanceWebview|musical_ly/i.test(userAgent)) {
        //     setIsInAppBrowser(true); // TikTok users will see the Google sign-in button
        //     setAppName('Tiktok');
        // } else if (/youtube|yt/i.test(userAgent)) {
        //     setIsInAppBrowser(true);
        //     setAppName('YouTube');
        // } else if (/twitter/i.test(userAgent)) {
        //     setIsInAppBrowser(true);
        //     setAppName('Twitter');
        // }
    };

    // Function to open Chrome using a deep link (for mobile)
    const openInChrome = () => {
        const url = 'www.TattooGPT.Art/Generate';
        if (/android/i.test(navigator.userAgent)) {
            window.location.href = `intent://${url}#Intent;package=com.android.chrome;end`; //TODO: CHECK IF ANDROID WORKS
        } else {
            window.location.href = `googlechrome://${url}`;
        }
    };

    useEffect(() => {
        checkIfInAppBrowser();
        // Automatically open in Chrome if in-app browser is detected -
        if (isInAppBrowser) {
            openInChrome();
        }
    }, [isInAppBrowser]); // Dependency on isInAppBrowser to trigger after detection

    return (
        <Container fluid className="google-login-page">
            <Row className="justify-content-center">
                <Col md={6}>
                    <Card className="text-center shadow-lg p-4 mb-5 bg-white rounded">
                        <Card.Body>
                            <Card.Title className="display-4 mb-3">Please Login</Card.Title>
                            <Card.Text className="lead mb-2">Sign in quickly with Google, <br/> New user
                                gets <b>FREE</b> AI Tattoo design🎁 </Card.Text>
                            <div className="login-button">
                                <GoogleLoginButtonLogic/>
                            </div>
                            <p className="extra-text">If you already have an account, we'll log you in</p>
                            <p className="extra-text2">dont see the button? copy this url to your browser www.TattooGPT.Art</p>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default LoginPage;
