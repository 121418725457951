import React, {useEffect} from 'react';
import axios from 'axios';
import {useLogin} from "./UserAutentication/LoginProvider";

const Logout = () => {
    const {isLoggedIn, setIsLoggedIn} = useLogin();

    const logout = async () => {
        try {
            const response = await axios.post(process.env.REACT_APP_BASE_SERVER_URL+'/api/v1/users/logout', null, {
                withCredentials: true,
                timeout: 30000 // Timeout
            });
            //TODO: verify response
            setIsLoggedIn(false)
            window.location.href = '/';
        } catch (error) {
            console.error('Error during logout');
        }
    };

    useEffect(() => {
        logout(); // Call the logout function when the component mounts
    }, []); // Use an empty dependency array to ensure this effect runs only once

    return null; // Return null to prevent rendering anything
};

export default Logout;
