import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {useNavigate} from 'react-router-dom';
import {PayPalScriptProvider, PayPalButtons, FUNDING} from "@paypal/react-paypal-js";
import {useLogin} from "../UserAutentication/LoginProvider";
import GoogleLoginButtonLogic from "../UserAutentication/GoogleLoginButtonLogic";
import "./CheckoutPage.css";

const CheckoutPage = ({product}) => {
    const {isLoggedIn} = useLogin();
    const navigate = useNavigate();
    const [isReady, setIsReady] = useState(false);

    // Extract isSandbox parameter from the URL
    const searchParams = new URLSearchParams(window.location.search);
    const isSandbox = searchParams.get('isSandbox') === '1'; //|| process.env.REACT_APP_IS_PROD == 0;

    // Determine clientId based on the isSandbox parameter
    const clientId = isSandbox
        ? process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX
        : process.env.REACT_APP_PAYPAL_CLIENT_ID_LIVE;

    useEffect(() => {
        if (clientId) {
            setIsReady(true);
        } else {
            console.error("PayPal Client ID is missing.");
        }
    }, [clientId]);


    const handleApproveOneTime = async (data, actions) => {
        try {
            const details = await actions.order.capture();

            const response = await axios.post(process.env.REACT_APP_BASE_SERVER_URL + '/api/v1/paypal-transaction-complete', {
                orderID: data.orderID,
                payerID: data.payerID,
                accountId: isLoggedIn,
                productId: product.productId,
                price: product.finalPrice,
                planType: product.billingMode,
            }, {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            });

            if (response.status === 200) {
                navigate('/thank-you', {
                    state: {
                        details,
                        productId: product.productId,
                        price: product.finalPrice,
                        productName: product.productName
                    }
                });
            } else {
                console.error('Error during subscription completion: Unexpected response status');

            }
        } catch (error) {
            console.error('Error during subscription completion:');
        }
    };


    const handleApproveSubscription = async (data, actions) => {
        try {
            const details = await actions.subscription.get();

            const {id, status, subscriber} = details;
            const {email_address, payer_id} = subscriber;

            const response = await axios.post(process.env.REACT_APP_BASE_SERVER_URL + '/api/v1/paypal-transaction-complete', {
                subscriptionID: id,
                UserEmail: email_address,
                payerId: payer_id,
                status: status,
                accountId: isLoggedIn,
                productId: product.productId,
                price: product.finalPrice,
                planType: product.billingMode
            }, {
                headers: {'Content-Type': 'application/json'},
                withCredentials: true
            });

            if (response.status === 200) {
                navigate('/thank-you', {
                    state: {
                        details,
                        productId: product.productId,
                        price: product.finalPrice,
                        productName: product.productName
                    }
                });
            } else {
                console.error('Error during subscription completion: Unexpected response status');

            }
        } catch (error) {
            console.error('Error during subscription completion:');
        }
    };


    const styles = {
        layout: 'vertical', // Default layout style for buttons
        //color: 'white',
        shape: 'rect',
        label: 'pay',
        tagline: false,
    };

    return (
        <div>
            {!isLoggedIn && (
                <div className='checkout-login-container'>
                    <GoogleLoginButtonLogic/>
                </div>
            )}
            {isLoggedIn && isReady && product && (
                (product.planType === 'one-time' || product.billingMode === 'one-time') ? (
                    <PayPalScriptProvider options={{"client-id": clientId, currency: 'USD'}}>
                        <div className="checkout-buttons">
                        <PayPalButtons
                            fundingSource={FUNDING.PAYPAL}  // Specify PayPal button
                            style={styles}
                            createOrder={(data, actions) => actions.order.create({
                                purchase_units: [{
                                    amount: {
                                        value: product.finalPrice.toFixed(2),
                                        currency_code: 'USD'
                                    },
                                    description: `TattooGPT one-time purchase: Product Id: ${product.productId}`
                                }],
                                application_context: {
                                    shipping_preference: 'NO_SHIPPING'
                                }
                            })}
                            onApprove={async (data, actions) => {
                                await handleApproveOneTime(data, actions);
                            }}
                        />
                        <PayPalButtons
                            fundingSource={FUNDING.CARD}   // Specify credit card button
                            style={styles}
                            createOrder={(data, actions) => actions.order.create({
                                purchase_units: [{
                                    amount: {
                                        value: product.finalPrice.toFixed(2),
                                        currency_code: 'USD',
                                        user_action: 'PAY_NOW'
                                    },
                                    description: `TattooGPT one-time purchase: Product Id: ${product.productId}`
                                }],
                                application_context: {
                                    shipping_preference: 'NO_SHIPPING'
                                }
                            })}
                            onApprove={async (data, actions) => {
                                await handleApproveOneTime(data, actions);
                            }}
                        />
                        </div>

                    </PayPalScriptProvider>
                ) : (
                    <PayPalScriptProvider options={{"client-id": clientId, vault: true, intent: 'subscription'}}>
                        <PayPalButtons
                            style={styles}
                            key={product.processorExternalIdSandbox}
                            createSubscription={(data, actions) => actions.subscription.create({
                                plan_id: isSandbox ? product.processorExternalIdSandbox : product.processorExternalIdLive,
                                application_context: {
                                    shipping_preference: 'NO_SHIPPING',
                                    landing_page: 'BILLING'
                                }
                            })}
                            onApprove={async (data, actions) => {
                                await handleApproveSubscription(data, actions);
                            }}
                        />
                    </PayPalScriptProvider>
                )
            )}
            {<p className='isSandboxDesclimer'>{isSandbox !== false && "*Sandbox mode"}</p>}
        </div>
    );
};

export default CheckoutPage;
